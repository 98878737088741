import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SlickDeals = () => {
  const [topDeals, setTopDeals] = useState([]);
  const [showComponent, setShowComponent] = useState(false); // Initially set to false
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        // Fetch JSON from the local directory
        const response = await fetch(`${process.env.PUBLIC_URL}/cs2stall.json`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        console.log("Fetched Data:", result); // Debugging line

        if (result && Array.isArray(result.data)) {
          // Filter items to include only those marked as "Top Deal" in the description
          const topDealItems = result.data.filter((post) => {
            return (
              post.item.description && post.item.description === "Top Deal"
            );
          });
          setTopDeals(topDealItems);
        } else {
          console.log("No items found in the response or incorrect structure");
        }
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1081) {
        setShowComponent(false); // Hide component if width < 1081
      } else {
        setShowComponent(true); // Show component if width >= 1081
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigateToInventory = () => {
    navigate("/inventory");
  };

  if (!showComponent) {
    return null; // Return null if showComponent is false
  }

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    cssEase: "linear",
  };

  return (
    <Box sx={{ textAlign: "center", py: 4 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        <span
          className="h3textHomeTopDeals"
          onClick={navigateToInventory}
          style={{ cursor: "pointer" }}
        >
          TOP DEALS:
        </span>
      </Typography>
      <div className="slider-container">
        <Slider {...settings}>
          {topDeals.map((deal, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "auto", // Ensure height adjusts automatically
                paddingTop: "30px", // Add padding top of 30px
              }}
            >
              <img
                src={`https://s.csfloat.com/m/${deal.item.cs2_screenshot_id}/playside.png?v=2`}
                alt={`Top Deal ${index + 1}`}
                onClick={navigateToInventory}
                style={{
                  cursor: "pointer",
                  maxWidth: "100%", // Make sure image scales down if needed
                  maxHeight: "400px", // Adjust the max height as necessary
                  objectFit: "contain", // Ensure the image scales correctly
                }}
              />
            </div>
          ))}
        </Slider>
      </div>
    </Box>
  );
};

export default SlickDeals;
