import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import PostWidget from "scenes/widgets/PostWidget";
import Navbar from "scenes/navbar";
import LayerTop from "scenes/navbartop/indextop";
import Footer from "scenes/footer";

const Inventory = () => {
  const [posts, setPosts] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [sortOrder, setSortOrder] = useState(""); // State for sorting order
  const [showCases, setShowCases] = useState(false);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        // Array of URLs to fetch from the API
        const urls = [
          "https://skinpawn.co/cs2stall1.json",
          // "https://skinpawn.co/cs2stall2.json",
          "https://skinpawn.co/cs2stall3.json",
        ];

        // Fetch data from all URLs
        const responses = await Promise.all(urls.map((url) => fetch(url)));

        // Check if all responses are OK
        responses.forEach((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
        });

        // Parse JSON from all responses
        const results = await Promise.all(
          responses.map((response) => response.json())
        );

        // Combine all data into a single array
        const combinedData = results.flatMap((result) => result.data || []);

        console.log("Fetched Data:", combinedData); // Debugging line

        setPosts(combinedData);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, []);

  const handleFilterChange = (event) => {
    console.log("Selected Filter:", event.target.value); // Debugging line
    setFilterValue(event.target.value);
  };

  const handleSortChange = (event) => {
    console.log("Selected Sort Order:", event.target.value); // Debugging line
    setSortOrder(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    console.log("Show Cases Checkbox:", event.target.checked); // Debugging line
    setShowCases(event.target.checked);
  };

  // Sort posts based on the selected sortOrder
  const sortedPosts = [...posts].sort((a, b) => {
    if (sortOrder === "lowToHigh") {
      return a.price - b.price;
    } else if (sortOrder === "highToLow") {
      return b.price - a.price;
    }
    return 0;
  });

  return (
    <>
      <LayerTop />
      <Navbar />
      {/* Filter and Sort Box */}
      <Box
        sx={{
          display: "inline-block",
          position: "relative",
          float: "right",
          width: "200px",
          zIndex: 1200,
          padding: "1rem",
          borderRadius: "8px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          margin: "10px",
        }}
      >
        <Select
          value={filterValue}
          onChange={handleFilterChange}
          displayEmpty
          fullWidth
          variant="outlined"
          sx={{ mb: 2 }}
        >
          <MenuItem value="">All Skins</MenuItem>
          <MenuItem value="withStickers">With Stickers</MenuItem>
          <MenuItem value="withoutStickers">Without Stickers</MenuItem>
          <MenuItem value="withKnives">Knives</MenuItem>
          <MenuItem value="withTopDeals">Top Deals</MenuItem>
        </Select>
        <Select
          value={sortOrder}
          onChange={handleSortChange}
          displayEmpty
          fullWidth
          variant="outlined"
        >
          <MenuItem value="">Default</MenuItem>
          <MenuItem value="lowToHigh">Price: Low to High</MenuItem>
          <MenuItem value="highToLow">Price: High to Low</MenuItem>
        </Select>
        <FormControlLabel
          control={
            <Checkbox checked={showCases} onChange={handleCheckboxChange} />
          }
          label="Show Cases and Capsules"
        />
      </Box>
      <WidgetWrapper className="postcontainer">
        <Grid container spacing={2}>
          {sortedPosts
            .filter((post) => {
              console.log("Post Description:", post.description); // Debugging line

              const hasStickers =
                post.item.stickers && post.item.stickers.length > 0;
              const isKnife = post.item.item_name
                .toLowerCase()
                .includes("knife");
              const isCaseOrCapsule = ["case", "capsule"].some((term) =>
                post.item.item_name.toLowerCase().includes(term)
              );
              const startsWithTop =
                post.description &&
                post.description.toLowerCase().startsWith("top");

              if (filterValue === "withStickers") {
                return hasStickers;
              } else if (filterValue === "withoutStickers") {
                return !hasStickers;
              } else if (filterValue === "withKnives") {
                return isKnife;
              } else if (filterValue === "withTopDeals") {
                return startsWithTop;
              }

              // Filter out cases and capsules if the checkbox is unchecked
              if (!showCases && isCaseOrCapsule) {
                return false;
              }

              return true;
            })
            .map((post, index) => (
              <Grid
                className="boxwrapper"
                item
                key={index}
                xs={12}
                sm={6}
                md={4}
                lg={2.4}
                xl={2.4}
              >
                <PostWidget
                  float_value={post.item.float_value}
                  is_stattrak={post.item.is_stattrak}
                  is_souvenir={post.item.is_souvenir}
                  item_name={post.item.item_name}
                  wear_name={post.item.wear_name}
                  cs2_screenshot_id={post.item.cs2_screenshot_id}
                  icon_url={post.item.icon_url}
                  watchers={post.watchers}
                  inspect_link={post.item.inspect_link}
                  price={post.price}
                  id={post.id}
                  stickers={post.item.stickers}
                  has_knife={post.item.has_knife}
                />
              </Grid>
            ))}
        </Grid>
      </WidgetWrapper>
      <Footer sx={{ position: "absolute", bottom: 0, width: "100%" }} />
    </>
  );
};

export default Inventory;
