import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faSteam } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const [footerHeight, setFooterHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const footer = document.getElementById("footer");
      setFooterHeight(footer.offsetHeight);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <footer
      id="footer"
      style={{
        ...footerStyle,
        marginTop: "145px", // Set marginTop to 10 pixels
        marginBottom: footerHeight, // Keep marginBottom for consistency
      }}
    >
      <div style={copyrightStyle}>
        <span>
          &copy; Made with &#10084; by{" "}
          <a href="/members" class="supers">
            Krisgfx
          </a>
        </span>

        {/* <div style={iconContainer}>
          <a
            href="https://twitter.com/krisgfx"
            target="_blank"
            rel="noopener noreferrer"
            className="footerIcons" // Add className for styling
          >
            <FontAwesomeIcon icon={faTwitter} style={iconStyle} />
          </a>
          <a
            href="https://steamcommunity.com/id/krisgfx"
            target="_blank"
            rel="noopener noreferrer"
            className="footerIcons" // Add className for styling
          >
            <FontAwesomeIcon icon={faSteam} style={iconStyle} />
          </a>
        </div> */}
      </div>
    </footer>
  );
};

const footerStyle = {
  backgroundColor: "#333",
  color: "#fff",
  textAlign: "center",
  padding: "1rem",
  width: "100%",
};

const copyrightStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const iconContainer = {
  marginLeft: "10px",
};

const iconStyle = {
  fontSize: "1.5rem",
  margin: "0 5px",
};

// Define CSS for .footerIcons class
const footerIconsStyle = {
  color: "black",
};

export default Footer;
