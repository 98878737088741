import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faSteam,
  faDiscord,
} from "@fortawesome/free-brands-svg-icons";

const DropDownProfile = () => {
  return (
    <div className="dropDownMenu">
      <ul>
        <li className="socials">
          <a
            href="https://twitter.com/SkinPawn"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
        </li>
        {/* <li className="socials">
          <a
            href="https://steamcommunity.com/id/SwooceCS"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faSteam} />
          </a>
        </li> */}
        <li className="socials">
          <a
            href="https://discord.gg/uCYZhJNJ6G"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faDiscord} />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default DropDownProfile;
