import { Typography, useMediaQuery } from "@mui/material";
import React from "react";
import Navbar from "scenes/navbar";
import LayerTop from "scenes/navbartop/indextop";
import { Box } from "@mui/material";
import Footer from "scenes/footer";
import Services2 from "./services2";

const Services = () => {
  const isScreenSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <>
      <LayerTop /> {/* First Navbar */}
      <Navbar /> {/* Second Navbar */}
      <Box className="containerServicesLefty">
        <Box>
          <h1 className="test3">Services</h1>
          <Typography component="div" className="test1">
            Whether you need to buy or sell individual skins, or need us to
            source an entire inventory for you, Skin Pawn can meet any need at
            any scale.
          </Typography>
          <h2 className="test2">Item Purchasing </h2>{" "}
          <Typography component="div" className="test1">
            {" "}
            Because Skin Pawn is a multi-trader partnership, we are able to
            offer fair prices for any item whether it be a liquid or non-liquid,
            low or high tier. We also offer many payment methods so you can sell
            your items safely and quickly using a method you are comfortable
            with.
          </Typography>
          <h2 className="test2">Item Sales </h2>
          <Typography component="div" className="test1">
            Skin Pawn is your source for a wide range of Counter-Strike 2 Items.
            We maintain an extensive inventory ranging from end game collector
            items to budget play skins. We strive to price these skins fairly.
          </Typography>
          <h2 className="test2">Pawning </h2>
          <Typography component="div" className="test1">
            A service unique to Skin Pawn, pawning is when a customer exchanges
            an item as collateral for a short-term cash loan. The item is
            returned to the customer when the loan has been paid back plus
            interest. Loan amount and interest rate are case by case. This
            service is very useful if you need money quickly but have a
            difficult item to sell or you don't want to lose your item.
          </Typography>
          {/* <h2 className="test2">Sourcing Items </h2>
          <Typography component="div" className="test1">
            As a United States based business we know how difficult it is to
            access Buff. Since 2022 it has become very difficult for citizens of
            the United States to access the marketplace. Skin Pawn offers a
            simple solution to this problem. We will buy any item you want on
            Buff for no extra cost and deliver it to you immediately when
            tradeable.
          </Typography> */}
          <h2 className="test2">Middleman/Brokering </h2>
          <Typography component="div" className="test1">
            We know how difficult it is to sell certain skins. Skin Pawn offers
            a wide range of options to help you get your skins sold at a price
            you are happy with. From posting on social media to messaging known
            collectors, we will do everything we can to spread the word. Ask
            about listing your Item on our website.
          </Typography>
          <h2 className="test2">Sales Commission </h2>
          <Typography component="div" className="test1">
            We are rewarding people who bring us successful deals. If you know
            someone looking for a safe and fair place to sell their skins refer
            them to us and collect 20% of the profit! If you are already trading
            skins you also might want to take advantage of this opportunity. We
            offer on skins many traders avoid so If you're unable to make an
            offer on something because it’s too illiquid or expensive send them
            our way! We ask all of our customers where they came from/who
            referred them so we will know that we owe you and add you to our
            spreadsheet.
          </Typography>
          {/* <h2 className="test2">Current Purchase Rates </h2>
          <Typography component="div" className="test1">
            (% based on Buff.163 List Price) 88-91% | most liquids 92% | stable
            or expensive liquids 93%+ | High tier ($5000+) stable liquids case
            by case | non-liquids Selling Buff Bal Rate (Exchange rate based on
            Buff rate when choosing USD as currency) 1 : 1 fees on us ($1 gets
            you the equivalent of $1 in CNY)
          </Typography> */}
        </Box>
        {!isScreenSmall && (
          <div className="myTwoLittleBoxes">
            <div className="ratesBox">
              <div className="ratesTextBox">
                <h2 className="bigTextBox">Current Purchase Rates</h2>
                <h2 className="underBigTextBox">
                  (% based on Current Market List Price)
                </h2>{" "}
                <h2>85-90% | most liquids</h2>
                <h2>90-92% | stable or expensive liquids</h2>{" "}
                {/* <h2>93%+ | High tier ($5000+) stable liquids</h2>{" "} */}
                <h2>case by case | non-liquids</h2>
              </div>
              <div className="ratesIconBoxes">
                {/* <div class="iconWithText">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 24 24"
                    className="servicesIcon"
                  >
                    <path
                      // fill="#000"
                      d="M13.559 24h-2.841a.483.483 0 0 1-.483-.483v-2.765H5.638a.667.667 0 0 1-.666-.666v-2.234a.67.67 0 0 1 .142-.412l8.139-10.382h-7.25a.667.667 0 0 1-.667-.667V3.914c0-.367.299-.666.666-.666h4.23V.483c0-.266.217-.483.483-.483h2.841c.266 0 .483.217.483.483v2.765h4.323c.367 0 .666.299.666.666v2.137a.67.67 0 0 1-.141.41l-8.19 10.481h7.665c.367 0 .666.299.666.666v2.477a.667.667 0 0 1-.666.667h-4.32v2.765a.483.483 0 0 1-.483.483"
                    ></path>
                  </svg>
                  <div class="textUnderIcon">Zelle</div>
                </div>
                <div class="iconWithText">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 24 24"
                    className="servicesIcon2"
                  >
                    <path
                      // fill="#000"
                      d="M6.488 7.469L0 15.05h11.585l1.301-3.576H7.922l3.033-3.507l.01-.092L8.993 4.48h8.873l-6.878 18.925h4.706L24 .595H2.543z'/%3E%3C/svg%3E"
                    ></path>
                  </svg>
                  <div class="textUnderIcon">Wise</div>
                </div>
                <div class="iconWithText">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 24 24"
                    className="servicesIcon3"
                  >
                    <path
                      // fill="#000"
                      d="M23.59 3.475a5.1 5.1 0 0 0-3.05-3.05c-1.31-.42-2.5-.42-4.92-.42H8.36c-2.4 0-3.61 0-4.9.4a5.1 5.1 0 0 0-3.05 3.06C0 4.765 0 5.965 0 8.365v7.27c0 2.41 0 3.6.4 4.9a5.1 5.1 0 0 0 3.05 3.05c1.3.41 2.5.41 4.9.41h7.28c2.41 0 3.61 0 4.9-.4a5.1 5.1 0 0 0 3.06-3.06c.41-1.3.41-2.5.41-4.9v-7.25c0-2.41 0-3.61-.41-4.91m-6.17 4.63l-.93.93a.5.5 0 0 1-.67.01a5 5 0 0 0-3.22-1.18c-.97 0-1.94.32-1.94 1.21c0 .9 1.04 1.2 2.24 1.65c2.1.7 3.84 1.58 3.84 3.64c0 2.24-1.74 3.78-4.58 3.95l-.26 1.2a.49.49 0 0 1-.48.39H9.63l-.09-.01a.5.5 0 0 1-.38-.59l.28-1.27a6.54 6.54 0 0 1-2.88-1.57v-.01a.48.48 0 0 1 0-.68l1-.97a.49.49 0 0 1 .67 0c.91.86 2.13 1.34 3.39 1.32c1.3 0 2.17-.55 2.17-1.42s-.88-1.1-2.54-1.72c-1.76-.63-3.43-1.52-3.43-3.6c0-2.42 2.01-3.6 4.39-3.71l.25-1.23a.48.48 0 0 1 .48-.38h1.78l.1.01c.26.06.43.31.37.57l-.27 1.37c.9.3 1.75.77 2.48 1.39l.02.02c.19.2.19.5 0 .68'/%3E%3C/svg%3E"
                    ></path>
                  </svg>
                  <div class="textUnderIcon">Cash App</div>
                </div>
                <div class="iconWithText">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 24 24"
                    className="servicesIcon4"
                  >
                    <path
                      // fill="#000"
                      d="M14.24 10.56c-.31 1.24-2.24.61-2.84.44l.55-2.18c.62.18 2.61.44 2.29 1.74m-3.11 1.56l-.6 2.41c.74.19 3.03.92 3.37-.44c.36-1.42-2.03-1.79-2.77-1.97m10.57 2.3c-1.34 5.36-6.76 8.62-12.12 7.28S.963 14.94 2.3 9.58A9.996 9.996 0 0 1 14.42 2.3c5.35 1.34 8.61 6.76 7.28 12.12m-7.49-6.37l.45-1.8l-1.1-.25l-.44 1.73c-.29-.07-.58-.14-.88-.2l.44-1.77l-1.09-.26l-.45 1.79c-.24-.06-.48-.11-.7-.17l-1.51-.38l-.3 1.17s.82.19.8.2c.45.11.53.39.51.64l-1.23 4.93c-.05.14-.21.32-.5.27c.01.01-.8-.2-.8-.2L6.87 15l1.42.36c.27.07.53.14.79.2l-.46 1.82l1.1.28l.45-1.81c.3.08.59.15.87.23l-.45 1.79l1.1.28l.46-1.82c1.85.35 3.27.21 3.85-1.48c.5-1.35 0-2.15-1-2.66c.72-.19 1.26-.64 1.41-1.62c.2-1.33-.82-2.04-2.2-2.52'/%3E%3C/svg%3E"
                    ></path>
                  </svg>
                  <div class="textUnderIcon">Crypto</div>
                </div> */}

                {/* You can add any additional content inside the div if needed */}
              </div>
            </div>
            <div className="ratesBox two">
              <div className="ratesTextBox2">
                <h2 className="bigTextBox">Payment Methods:</h2>
                <div className="ratesIconBoxes">
                  <div class="iconWithText">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2em"
                      height="2em"
                      viewBox="0 0 24 24"
                      className="servicesIcon"
                    >
                      <path
                        // fill="#000"
                        d="M13.559 24h-2.841a.483.483 0 0 1-.483-.483v-2.765H5.638a.667.667 0 0 1-.666-.666v-2.234a.67.67 0 0 1 .142-.412l8.139-10.382h-7.25a.667.667 0 0 1-.667-.667V3.914c0-.367.299-.666.666-.666h4.23V.483c0-.266.217-.483.483-.483h2.841c.266 0 .483.217.483.483v2.765h4.323c.367 0 .666.299.666.666v2.137a.67.67 0 0 1-.141.41l-8.19 10.481h7.665c.367 0 .666.299.666.666v2.477a.667.667 0 0 1-.666.667h-4.32v2.765a.483.483 0 0 1-.483.483"
                      ></path>
                    </svg>
                    <div class="textUnderIcon">Zelle</div>
                  </div>
                  <div class="iconWithText">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2em"
                      height="2em"
                      viewBox="0 0 24 24"
                      className="servicesIcon2"
                    >
                      <path
                        // fill="#000"
                        d="M6.488 7.469L0 15.05h11.585l1.301-3.576H7.922l3.033-3.507l.01-.092L8.993 4.48h8.873l-6.878 18.925h4.706L24 .595H2.543z'/%3E%3C/svg%3E"
                      ></path>
                    </svg>
                    <div class="textUnderIcon">Wise</div>
                  </div>
                  <div class="iconWithText">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2em"
                      height="2em"
                      viewBox="0 0 24 24"
                      className="servicesIcon3"
                    >
                      <path
                        // fill="#000"
                        d="M23.59 3.475a5.1 5.1 0 0 0-3.05-3.05c-1.31-.42-2.5-.42-4.92-.42H8.36c-2.4 0-3.61 0-4.9.4a5.1 5.1 0 0 0-3.05 3.06C0 4.765 0 5.965 0 8.365v7.27c0 2.41 0 3.6.4 4.9a5.1 5.1 0 0 0 3.05 3.05c1.3.41 2.5.41 4.9.41h7.28c2.41 0 3.61 0 4.9-.4a5.1 5.1 0 0 0 3.06-3.06c.41-1.3.41-2.5.41-4.9v-7.25c0-2.41 0-3.61-.41-4.91m-6.17 4.63l-.93.93a.5.5 0 0 1-.67.01a5 5 0 0 0-3.22-1.18c-.97 0-1.94.32-1.94 1.21c0 .9 1.04 1.2 2.24 1.65c2.1.7 3.84 1.58 3.84 3.64c0 2.24-1.74 3.78-4.58 3.95l-.26 1.2a.49.49 0 0 1-.48.39H9.63l-.09-.01a.5.5 0 0 1-.38-.59l.28-1.27a6.54 6.54 0 0 1-2.88-1.57v-.01a.48.48 0 0 1 0-.68l1-.97a.49.49 0 0 1 .67 0c.91.86 2.13 1.34 3.39 1.32c1.3 0 2.17-.55 2.17-1.42s-.88-1.1-2.54-1.72c-1.76-.63-3.43-1.52-3.43-3.6c0-2.42 2.01-3.6 4.39-3.71l.25-1.23a.48.48 0 0 1 .48-.38h1.78l.1.01c.26.06.43.31.37.57l-.27 1.37c.9.3 1.75.77 2.48 1.39l.02.02c.19.2.19.5 0 .68'/%3E%3C/svg%3E"
                      ></path>
                    </svg>
                    <div class="textUnderIcon">Cash App</div>
                  </div>

                  {/* You can add any additional content inside the div if needed */}
                </div>
                <div className="ratesIconBoxes">
                  <div class="iconWithText">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2em"
                      height="2em"
                      viewBox="0 0 24 24"
                      className="servicesIcon4"
                    >
                      <path
                        // fill="#000"
                        d="M14.24 10.56c-.31 1.24-2.24.61-2.84.44l.55-2.18c.62.18 2.61.44 2.29 1.74m-3.11 1.56l-.6 2.41c.74.19 3.03.92 3.37-.44c.36-1.42-2.03-1.79-2.77-1.97m10.57 2.3c-1.34 5.36-6.76 8.62-12.12 7.28S.963 14.94 2.3 9.58A9.996 9.996 0 0 1 14.42 2.3c5.35 1.34 8.61 6.76 7.28 12.12m-7.49-6.37l.45-1.8l-1.1-.25l-.44 1.73c-.29-.07-.58-.14-.88-.2l.44-1.77l-1.09-.26l-.45 1.79c-.24-.06-.48-.11-.7-.17l-1.51-.38l-.3 1.17s.82.19.8.2c.45.11.53.39.51.64l-1.23 4.93c-.05.14-.21.32-.5.27c.01.01-.8-.2-.8-.2L6.87 15l1.42.36c.27.07.53.14.79.2l-.46 1.82l1.1.28l.45-1.81c.3.08.59.15.87.23l-.45 1.79l1.1.28l.46-1.82c1.85.35 3.27.21 3.85-1.48c.5-1.35 0-2.15-1-2.66c.72-.19 1.26-.64 1.41-1.62c.2-1.33-.82-2.04-2.2-2.52'/%3E%3C/svg%3E"
                      ></path>
                    </svg>
                    <div class="textUnderIcon">Crypto</div>
                  </div>
                  <div class="iconWithText">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2em"
                      height="2em"
                      viewBox="0 0 24 24"
                      className="servicesIcon5"
                    >
                      <path
                        // fill="#000"
                        d="M21.495 7.054c-1.07 4.973-4.48 7.604-9.89 7.604H9.643L8.18 24h3.182c.46 0 .85-.334.923-.788l.037-.198l.732-4.636l.047-.256a.93.93 0 0 1 .922-.788h.581c3.76 0 6.705-1.528 7.565-5.946c.345-1.773.179-3.26-.674-4.334M19.317 1.81C18.206.543 16.197 0 13.627 0H6.169c-.526 0-.973.383-1.055.9L2.008 20.598a.64.64 0 0 0 .633.74h4.604l1.157-7.334l-.036.229c.082-.518.526-.9 1.051-.9h2.188c4.299 0 7.664-1.747 8.648-6.797c.029-.15.076-.437.076-.437c.279-1.87-.002-3.138-1.012-4.288'/%3E%3C/svg%3E"
                      ></path>
                    </svg>
                    <div class="textUnderIcon">Paypal</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Box>
      <Services2 />
      <Footer sx={{ position: "absolute", bottom: 0, width: "100%" }} />
    </>
  );
};

export default Services;
