import { useState } from "react";
import {
  Box,
  IconButton,
  InputBase,
  Typography,
  Select,
  MenuItem,
  FormControl,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Search, DarkMode, LightMode, Menu, Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setMode, setLogout } from "state";
import { useNavigate } from "react-router-dom";
import FlexBetween from "components/FlexBetween";

const Navbar = () => {
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  const [darkModeClicked, setDarkModeClicked] = useState(false); // Track dark mode button click
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const isSmallScreen = useMediaQuery("(max-width: 999px)");

  const theme = useTheme();
  const neutralLight = theme.palette.neutral.light;
  const dark = theme.palette.neutral.dark;
  const background = theme.palette.background.default;
  const primaryLight = theme.palette.primary.light;
  const alt = theme.palette.background.alt;

  // Check if user is authenticated
  const isAuthenticated = !!user;

  // Check if user object exists and has firstName and lastName properties
  const fullName = user ? `${user.firstName} ${user.lastName}` : "";

  return (
    <FlexBetween padding="1rem 6%" backgroundColor={alt}>
      <Box
        onClick={() => navigate("/home")}
        sx={{
          "&:hover": {
            cursor: "pointer",
            filter: "brightness(70%)",
          },
        }}
        className="logo"
      >
        <FlexBetween gap="1.75rem" alignItems="center">
          <img src="/logo.png" alt="Logo" className="a" />
        </FlexBetween>
      </Box>

      {/* Inventory, Members, and Services for Desktop */}
      {!isSmallScreen && (
        <FlexBetween gap="2rem" alignItems="center">
          <Typography
            fontWeight="bold"
            fontSize="clamp(1rem, 1.3rem, 1.25rem)"
            color="primary"
            onClick={() => navigate("/inventory")}
            sx={{
              "&:hover": {
                cursor: "pointer",
                color: primaryLight,
              },
            }}
          >
            Inventory
          </Typography>
          <Typography
            fontWeight="bold"
            fontSize="clamp(1rem, 1.3rem, 1.25rem)"
            color="primary"
            onClick={() => navigate("/services")}
            sx={{
              "&:hover": {
                cursor: "pointer",
                color: primaryLight,
              },
            }}
          >
            Services
          </Typography>
          <Typography
            fontWeight="bold"
            fontSize="clamp(1rem, 1.3rem, 1.25rem)"
            color="primary"
            onClick={() => navigate("/members")}
            sx={{
              "&:hover": {
                cursor: "pointer",
                color: primaryLight,
              },
            }}
          >
            Members
          </Typography>

          {/* Dark Mode Toggle */}
          <IconButton
            onClick={() => {
              dispatch(setMode());
              setDarkModeClicked(!darkModeClicked);
            }}
          >
            {theme.palette.mode === "dark" ? (
              <DarkMode
                sx={{
                  color: darkModeClicked ? "#fff" : "#fff",
                  fontSize: "25px",
                }}
              />
            ) : (
              <LightMode sx={{ color: "#000", fontSize: "25px" }} />
            )}
          </IconButton>
        </FlexBetween>
      )}

      {/* Burger Menu for Small Screens */}
      {isSmallScreen && (
        <IconButton
          onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
        >
          {isMobileMenuToggled ? <Close /> : <Menu />}
        </IconButton>
      )}

      {/* MOBILE NAV */}
      {isSmallScreen && isMobileMenuToggled && (
        <Box
          position="fixed"
          right="0"
          bottom="0"
          height="100%"
          zIndex="10"
          maxWidth="500px"
          minWidth="300px"
          backgroundColor={background}
        >
          {/* CLOSE ICON */}
          <Box display="flex" justifyContent="flex-end" p="1rem">
            <IconButton
              onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
            >
              <Close />
            </IconButton>
          </Box>

          {/* MENU ITEMS */}
          <FlexBetween
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="1rem" // Adjust spacing between menu items
          >
            <Typography
              fontWeight="bold"
              fontSize="clamp(1rem, 1.3rem, 1.25rem)"
              color="primary"
              onClick={() => navigate("/inventory")}
            >
              Inventory
            </Typography>
            <Typography
              fontWeight="bold"
              fontSize="clamp(1rem, 1.3rem, 1.25rem)"
              color="primary"
              onClick={() => navigate("/services")}
            >
              Services
            </Typography>
            <Typography
              fontWeight="bold"
              fontSize="clamp(1rem, 1.3rem, 1.25rem)"
              color="primary"
              onClick={() => navigate("/members")}
            >
              Members
            </Typography>

            {/* Dark Mode Toggle */}
            <IconButton
              onClick={() => {
                dispatch(setMode());
                setDarkModeClicked(!darkModeClicked);
              }}
            >
              {theme.palette.mode === "dark" ? (
                <DarkMode
                  sx={{
                    color: darkModeClicked ? "#fff" : "#fff",
                    fontSize: "25px",
                  }}
                />
              ) : (
                <LightMode sx={{ color: "#000", fontSize: "25px" }} />
              )}
            </IconButton>

            {/* Profile Dropdown */}
            {isAuthenticated && (
              <FormControl variant="standard" value={fullName}>
                <Select
                  value={fullName}
                  sx={{
                    backgroundColor: neutralLight,
                    width: "150px",
                    borderRadius: "0.25rem",
                    p: "0.25rem 1rem",
                    "& .MuiSvgIcon-root": {
                      pr: "0.25rem",
                      width: "3rem",
                    },
                    "& .MuiSelect-select:focus": {
                      backgroundColor: neutralLight,
                    },
                  }}
                  input={<InputBase />}
                >
                  <MenuItem value={fullName}>
                    <Typography>{fullName}</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => dispatch(setLogout())}>
                    Log Out
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          </FlexBetween>
        </Box>
      )}
    </FlexBetween>
  );
};

export default Navbar;
