import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import UserWidget from "scenes/widgets/UserWidget";
import MyPostWidget from "scenes/widgets/MyPostWidget";
import PostsWidget from "scenes/widgets/PostsWidget";
import AdvertWidget from "scenes/widgets/AdvertWidget";
import FriendListWidget from "scenes/widgets/FriendListWidget";
import { Typography } from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import DropDownProfile from "./dropdown";
import SlickDeals from "./topdeals";
import LayerTop from "scenes/navbartop/indextop";
import Footer from "scenes/footer";
import SlickDeals2 from "./topdeals2";

const HomePage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const user = useSelector((state) => state.user);
  const isLoggedIn = user !== null;
  const _id = isLoggedIn ? user._id : null;
  const picturePath = isLoggedIn ? user.picturePath : null;
  const [openDropDown, setOpenDropDown] = React.useState(false);
  const [openDropDownTop, setOpenDropDownTop] = React.useState(false);
  const [openDropDownBottom, setOpenDropDownBottom] = React.useState(false);
  const theme = useTheme();
  const bannerImageSrc =
    theme.palette.mode === "dark" ? "./13.jpeg" : "./14.png";

  return (
    <Box>
      <LayerTop />
      <Navbar />
      <Box className="topBoxHomePage">
        {isLoggedIn && (
          <>
            <Box className={isNonMobileScreens ? "topBoxHomePage2" : undefined}>
              <UserWidget userId={_id} picturePath={picturePath} />
            </Box>
            <Box
              className={isNonMobileScreens ? "topBoxHomePage3" : undefined}
              mt={isNonMobileScreens ? undefined : "2rem"}
            >
              <MyPostWidget picturePath={picturePath} />
              <PostsWidget userId={_id} />
            </Box>
            {isNonMobileScreens && (
              <Box className="topBoxHomePage4">
                <AdvertWidget />
                <Box m="2rem 0" />
                <FriendListWidget userId={_id} />
              </Box>
            )}
          </>
        )}
        {!isLoggedIn && (
          <>
            <Box className="topBoxHomePage5">
              <img className="banner" alt="user" src={bannerImageSrc} />
            </Box>
          </>
        )}
      </Box>
      <Box className="lefty-container">
        <WidgetWrapper className="widgetWrap phone-padding">
          <Box className="topBoxHomePage6">
            <textarea className="boxSkinpawn"></textarea>
            <Typography className="h1Skinpawn" variant="body1" component="div">
              WHAT IS SKINPAWN?
            </Typography>
            <Typography
              variant="body1"
              component="div"
              className="spText1" // Apply the CSS class here
            >
              Skin Pawn is a business run by traders offering CS2 skin services.{" "}
              To get started{" "}
              <div
                onClick={() => setOpenDropDownTop((prev) => !prev)}
                style={{
                  display: "inline-block",
                  cursor: "pointer",
                  position: "relative",
                  marginRight: "10px", // Add margin-right for space between the text and the dropdown
                }}
              >
                <span
                  style={{
                    color: "rgb(1, 219, 252)",
                  }}
                >
                  contact us
                </span>
                {openDropDownTop && <DropDownProfile />}.
              </div>
              {/* to get started. */}
              <br /> <br />
              With our extensive knowledge and community connections we are able
              to satisfy all your Counter-Strike 2 skin needs. To see a full
              list of the services we offer please visit our{" "}
              <a href="/services" className="supers">
                services
              </a>
              .
              <br />
              <br />
              We have a large inventory of fairly priced items from inexpensive
              liquids to end game collector pieces. To see the list of skins we
              have available and our discounted items please visit our{" "}
              <a href="/inventory" className="supers">
                inventory
              </a>
              . <br />
              <br />
              We are always looking to expand our inventory. We offer
              competitive rates for liquids, non-liquids and even whole
              inventories. For a free appraisal or to sell your items{" "}
              <div
                onClick={() => setOpenDropDownBottom((prev) => !prev)}
                style={{
                  display: "inline-block",
                  cursor: "pointer",
                  position: "relative",
                  marginRight: "10px", // Add margin-right for space between the text and the dropdown
                }}
              >
                <span
                  style={{
                    color: "rgb(1, 219, 252)",
                  }}
                >
                  contact us
                </span>
                {openDropDownBottom && <DropDownProfile />}.
              </div>
            </Typography>
          </Box>
          <Box>{/* Additional content */}</Box>
        </WidgetWrapper>
        <SlickDeals />
      </Box>
      <SlickDeals2 />
      <Footer sx={{ position: "absolute", bottom: 0, width: "100%" }} />
      <Box className="container">{/* <SlickDeals /> */}</Box>
    </Box>
  );
};

export default HomePage;
