import { useState } from "react";
import { Box, IconButton, Typography, Menu, MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FlexBetween from "components/FlexBetween";
import TwitterIcon from "@mui/icons-material/Twitter";

const LayerTop = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <FlexBetween
      padding="0.5rem 3%"
      backgroundColor="transparent"
      sx={{ justifyContent: "flex-end" }} // Align items to the right
    >
      {/* Contact Us */}
      <Typography
        fontWeight="bold"
        fontSize="1.2rem" // Increased font size
        color="primary"
        sx={{
          "&:hover": {
            color: theme.palette.primary.light,
            cursor: "pointer",
          },
          ml: 0.5, // Add margin to the left
        }}
        onClick={handleMenuOpen} // Open menu on click
      >
        Contact Us
      </Typography>

      {/* Dropdown Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem>
          <IconButton
            href="https://twitter.com/SkinPawn"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <i
              className="fa-brands fa-twitter"
              style={{ fontSize: "1.5rem" }}
            />{" "}
          </IconButton>
        </MenuItem>
        {/* <MenuItem>
          <IconButton
            href="https://steamcommunity.com/id/SwooceCS/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <i className="fa-brands fa-steam" style={{ fontSize: "1.5rem" }} />{" "}
           
          </IconButton>
        </MenuItem> */}
        <MenuItem>
          <IconButton
            href="https://discord.gg/uCYZhJNJ6G"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <i
              className="fa-brands fa-discord"
              style={{ fontSize: "1.5rem" }}
            />{" "}
            {/* Discord icon */}
          </IconButton>
        </MenuItem>
      </Menu>
    </FlexBetween>
  );
};

export default LayerTop;
