import React, { useEffect, useCallback, useState } from "react";
import { useSelector } from "react-redux";

const PostsWidget = () => {
  const [posts, setPosts] = useState([]);
  // Assuming token is currently unused, you can remove this line if it's unnecessary.
  // const token = useSelector((state) => state.auth.token);

  const fetchExternalPosts = useCallback(async () => {
    try {
      const response = await fetch("YOUR_API_ENDPOINT");
      const data = await response.json();
      setPosts(data.items || []);
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  }, []);

  useEffect(() => {
    fetchExternalPosts();
  }, [fetchExternalPosts]); // Add the function to the dependency array

  return (
    <div>
      {posts.map((post) => (
        <div key={post.id}>{post.title}</div>
      ))}
    </div>
  );
};

export default PostsWidget;
