import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SlickDeals2 = () => {
  const [topDeals, setTopDeals] = useState([]);
  const [showComponent, setShowComponent] = useState(false); // Initially set to false
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTopDeals = async () => {
      try {
        const response = await fetch("http://api.skinpawn.co:3001/posts");
        const data = await response.json();
        console.log("Fetched top deals:", data); // Log fetched data
        const topDealsData = data.filter((post) => post.topDeals === "true");
        setTopDeals(topDealsData);
      } catch (error) {
        console.error("Error fetching top deals:", error);
      }
    };

    fetchTopDeals();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1080) {
        setShowComponent(true); // Show component if screen size is above 412x915
      } else {
        setShowComponent(false); // Hide component if screen size is below 412x915
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigateToInventory = () => {
    navigate("/inventory");
  };

  if (!showComponent) {
    return null; // Return null if showComponent is false
  }

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    cssEase: "linear",
  };

  return (
    <Box>
      <Box className="homeTopDealsText">
        <Typography>
          <h2
            className="h3textHomeTopDeals"
            onClick={navigateToInventory}
            style={{ cursor: "pointer" }}
          >
            TOP DEALS:
          </h2>
        </Typography>
        <div className="slider-container">
          <Slider {...settings}>
            {topDeals.map((deal, index) => (
              <div key={index}>
                <img
                  src={`http://api.skinpawn.co:3001/assets/${deal.picturePath}`}
                  alt={`Top Deal ${index + 1}`}
                  onClick={navigateToInventory}
                  style={{ cursor: "pointer" }}
                />
              </div>
            ))}
          </Slider>
        </div>
      </Box>
    </Box>
  );
};

export default SlickDeals2;
