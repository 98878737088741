import { Box } from "@mui/material";
import { styled } from "@mui/system";

const WidgetWrapper = styled(Box)(({ theme }) => ({
  padding: "1.5rem 1.5rem 0.75rem 1.5rem",
  // backgroundColor: theme.palette.background.alt,
  borderRadius: "0.75rem",

  // Media query for screens smaller than 768px
  "&.phone-padding": {
    "@media (max-width: 768px)": {
      padding: "0",
    },
  },
}));

export default WidgetWrapper;
