import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton, Button, Tooltip } from "@mui/material";
import {
  CameraAlt as CameraAltIcon,
  Search as SearchIcon,
  Visibility as EyeIcon,
} from "@mui/icons-material";
import WidgetWrapper from "components/WidgetWrapper";

const PostWidget = ({
  float_value = "0.00",
  is_stattrak = false,
  is_souvenir = false,
  item_name = "Default Item",
  wear_name = "Default Wear",
  cs2_screenshot_id,
  icon_url = "path/to/placeholder-image.png",
  watchers = 0,
  inspect_link = "#",
  price = 0,
  id, // Add the id here to construct the link
  stickers = [], // Ensure stickers data is accepted
}) => {
  const [isScreenshotExpanded, setIsScreenshotExpanded] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    // Set the image URL using Steam's CDN
    setImageUrl(
      `https://community.akamai.steamstatic.com/economy/image/${icon_url}`
    );
  }, [icon_url]);

  const handleImageError = () => {
    if (!imageError) {
      setImageError(true);
      setImageUrl(
        `https://community.akamai.steamstatic.com/economy/image/${icon_url}`
      ); // Use fallback image
    }
  };

  // Convert price to correct format by dividing by 100
  const formattedPrice = (price / 100).toFixed(2);

  // Construct the "Buy Now" link using the id
  const buyNowLink = `https://csfloat.com/item/${id}`;

  return (
    <WidgetWrapper className="boxtest" m="0.25rem" position="relative">
      {/* Top Row: Eye Icon, ST™, Item Name, Camera, and Magnifier Icons */}
      <Box
        position="absolute"
        top="0.5rem"
        left="0.5rem"
        right="0.5rem"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        zIndex="10" /* Ensure icons are above the image */
      >
        {/* Eye Icon with Watchers Count */}
        <Box display="flex" alignItems="center" color="lightgreen">
          <EyeIcon sx={{ fontSize: "1.2rem" }} />
          <Typography
            sx={{ ml: "0.25rem", fontWeight: "bold", fontSize: "0.9rem" }}
          >
            {watchers}
          </Typography>
        </Box>

        {/* ST™ Label and Item Name */}
        <Box display="flex" alignItems="center" color="white" gap="0.5rem">
          {is_stattrak && (
            <Box
              bgcolor="rgba(0, 0, 0, 0.5)"
              color="orange"
              borderRadius="5px"
              padding="0.25rem 0.5rem"
              fontWeight="bold"
              display="flex"
              alignItems="center"
            >
              ST™
            </Box>
          )}
          <Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}>
            {item_name}
          </Typography>
        </Box>

        {/* Camera and Magnifier Icons */}
        <Box display="flex" alignItems="center" gap="0.5rem">
          {cs2_screenshot_id && (
            <>
              <IconButton
                className="camera-icon"
                style={{
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  borderRadius: "50%",
                  zIndex: "15", // Ensure icon is above the image
                }}
                onClick={() => setIsScreenshotExpanded(true)}
              >
                <CameraAltIcon />
              </IconButton>

              <IconButton
                className="magnifier-icon"
                style={{
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  borderRadius: "50%",
                  zIndex: "15", // Ensure icon is above the image
                }}
                onClick={() => window.open(inspect_link, "_blank")}
              >
                <SearchIcon />
              </IconButton>
            </>
          )}
        </Box>
      </Box>

      {/* Image Container */}
      <Box position="relative" className="image-container">
        <img
          className="thumbnail-image"
          alt="post"
          src={imageUrl}
          onError={handleImageError}
        />

        {/* Wear and Float Information Above Price */}
        <Box
          position="absolute"
          bottom="2.5rem"
          left="0.5rem"
          bgcolor="rgba(0, 0, 0, 0.5)"
          color="white"
          borderRadius="5px"
          padding="0.25rem 0.5rem"
          fontWeight="bold"
        >
          <Typography sx={{ fontSize: "0.8rem" }}>Wear: {wear_name}</Typography>
          <Typography sx={{ fontSize: "0.8rem" }}>
            Float: {parseFloat(float_value).toFixed(2)}
          </Typography>
        </Box>

        {/* Price at the Bottom Left and "Buy Now" Button at the Bottom Right */}
        <Box
          position="absolute"
          bottom="0.5rem"
          left="0.5rem"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="calc(100% - 1rem)"
        >
          {/* Price */}
          <Box
            bgcolor="rgba(0, 0, 0, 0.5)"
            color="lightgreen"
            borderRadius="5px"
            padding="0.25rem 0.5rem"
            fontWeight="bold"
            zIndex="20"
          >
            ${formattedPrice}
          </Box>

          {/* "Buy Now" Button */}
          <Button
            variant="contained"
            size="small"
            sx={{
              bgcolor: "rgba(0, 0, 0, 0.5)", // Initial background color
              color: "white",
              fontWeight: "bold",
              textTransform: "none",
              marginLeft: "auto", // Ensure button is at the end
              zIndex: "20", // Ensure button is on top of other elements
              position: "relative", // Adjust positioning
              "&:hover": {
                color: "#000",
                bgcolor: "#FFF", // Darker background on hover
              },
            }}
            onClick={() => {
              console.log("Opening Link:", buyNowLink); // Debugging line
              window.open(buyNowLink, "_blank");
            }}
          >
            Buy Now
          </Button>
        </Box>
      </Box>
      {/* Stickers */}
      <Box className="stickers">
        {stickers.map((sticker, index) => (
          <Tooltip title={sticker.name} key={index}>
            <img
              src={sticker.icon_url}
              alt={`sticker-${index}`}
              style={{
                width: "35px",
                height: "35px",
                objectFit: "cover",
                cursor: "default", // Non-clickable cursor
              }}
            />
          </Tooltip>
        ))}
      </Box>
      {isScreenshotExpanded && (
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100%"
          height="100%"
          backgroundColor="rgba(0,0,0,0.8)"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="999"
          onClick={() => setIsScreenshotExpanded(false)}
        >
          <img
            className="expanded-image"
            src={`https://s.csfloat.com/m/${cs2_screenshot_id}/playside.png?v=2`}
            alt="expanded"
            onError={handleImageError}
          />
        </Box>
      )}
    </WidgetWrapper>
  );
};

export default PostWidget;
