import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import HomePage from "scenes/homePage";
import LoginPage from "scenes/loginPage";
import ProfilePage from "scenes/profilePage";
import Inventory from "Inventory";
import Members from "scenes/Members";
import Services from "scenes/services";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";

function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const isAuth = Boolean(useSelector((state) => state.token));

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<HomePage />} />/
            {/*<Route path="/" element={<LoginPage />} />*/}
            <Route
              path="/home"
              element={<HomePage />}
              //element={isAuth ? <HomePage /> : <Navigate to="/" />}
            />
            <Route
              path="/profile/:userId"
              element={ <ProfilePage />}
              //element={isAuth ? <ProfilePage /> : <Navigate to="/" />}
            />
            <Route
              path="/Login"
              element={ <LoginPage />}
              //element={isAuth ? <ProfilePage /> : <Navigate to="/" />}
            />
                        <Route
              path="/Inventory"
              element={ <Inventory />}
              //element={isAuth ? <ProfilePage /> : <Navigate to="/" />}
            />
                                    <Route
              path="/Members"
              element={ <Members />}
              //element={isAuth ? <ProfilePage /> : <Navigate to="/" />}
            />
                       
                                    <Route
              path="/Services"
              element={ <Services />}
              //element={isAuth ? <ProfilePage /> : <Navigate to="/" />}
            />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
